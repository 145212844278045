import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, AuthGuard } from 'auth';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import * as admin from 'admin';
import * as client from 'client';

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'client',
    canActivate: [AuthGuard],
    component: client.ClientLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./lazy-loading/client-lib-wrapper.module').then(m => m.ClientLibWrapperModule)
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    component: admin.AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./lazy-loading/admin-lib-wrapper.module').then(m => m.AdminLibWrapperModule)
      }
    ]
  },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
