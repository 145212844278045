import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { httpInterceptorProviders } from "./http-interceptors";
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from 'utilities';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { environment } from './../environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent, 
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    NgxSpinnerModule
  ],
  providers: [
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() 
  { 
    if (!window["apiUrl"]) 
    {
      window["apiUrl"] = `${environment.apiUrl}`;
    }
    if (!window["mediaUrl"]){
      window["mediaUrl"] = `${environment.mediaUrl}`;
    }
    if (!window["isArrCapture"]){
      window["isArrCapture"] = `${environment.isArrCapture}`;
    }
    if (!window["apiScanUrl"]) 
    {
      window["apiScanUrl"] = `${environment.apiScanUrl}`;
    }
    if (!window["isScanImage"]) 
    {
      window["isScanImage"] = `${environment.isScanImage}`;
    }

  }

 }
